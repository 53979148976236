module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Tela Systems","short_name":"Tela","start_url":"/","background_color":"#072B31","display":"minimal-ui","icon":"src/images/TS_Icon_Green_Square.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"61fc9d5a05061705515671fd21dd0d0c"},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableLister":true,"preconnect":["https://use.typekit.net"],"web":[{"name":"pragmatica","file":"https://use.typekit.net/ath1frk.css"},{"name":"pragmatica-condensed","file":"https://use.typekit.net/ath1frk.css"},{"name":"pragmatica-extended","file":"https://use.typekit.net/ath1frk.css"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
